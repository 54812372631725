import React from 'react';
import { createRoot } from 'react-dom/client';
import '@reach/dialog/styles.css';
import '@reach/tooltip/styles.css';
import './index.css';
import '../src/styles/typography.scss';
import reportWebVitals from './reportWebVitals';
import { IConfig, setConfig } from './config';

setConfig().then((conf) => {
  // remove evil cookie
  document.cookie = 'ln_or=; Max-Age=0; path=/; domain=' + window.location.host;
  init(conf);
});

const init = async (conf: IConfig) => {
  const { App } = await import('./App');
  const container = document.getElementById('root');
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript

  root.render(
    <React.StrictMode>
      <App config={conf} />
    </React.StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};
