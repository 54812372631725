export const setConfig = async () =>
  fetch('/appconfig.json')
    .then((response) => response.json())
    .then((cfg) => {
      AppConfig = {
        ...AppConfig,
        ...cfg.appConfig,
      };
      return AppConfig;
    })
    .catch(() => {
      return AppConfig;
    });

export interface IConfig {
  API_URL: string;
  AZURE_AUTHORITY: string;
  AZURE_CLIENT: string;
  SHOW_WARNING: string;
  DISABLE_INVOICES: string;
}

export let AppConfig: IConfig = {
  API_URL: '',
  AZURE_AUTHORITY: '',
  AZURE_CLIENT: '',
  SHOW_WARNING: '',
  DISABLE_INVOICES: '',
};
